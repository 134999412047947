!function($) {
	let map;
	let groups = {};
	let addresses = [];
	let savedMarkers = [];
	let activeGroup = null;
	const navContainer = $('#pointsOfSeilNav');
	const pointsSlider = $('#pointsSlider');
	const form = $('#pointsOfSeilForm');
	const citySelect = $('#pointsOfSeilSelect');
	const wrapper = $('.sellpoint-wrapper');

	window.initVektaMap = function() {
		map = new google.maps.Map(document.getElementById('pointsOfSeil'), {
		  center: {lng: 37.498053, lat: 55.819287},
		  zoom: 11,
		  styles: window.vektaMapStyle || []
		});
		getPoints(null, true);
		$('#pointsOfSeilMyLocation').on('click', getPoints)
	};

	pointsSlider.slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		fade: true,
		infinite: false
	})

	function groupBy (xs, key) {
	  return xs.reduce(function(rv, x) {
	    (rv[x[key]] = rv[x[key]] || []).push(x);
	    return rv;
	  }, {});
	}

	function getPoints (e, flag) {
		console.log("e, flag: ", e, flag);
		wrapper.height(wrapper.height());
		pointsSlider.slick('slickRemove', null, null, true);
		const options = {
			data: form.serialize(),
			method: form.attr('method'),
			url: form.attr('action'),
			dataType: 'json'
		}

		const request = $.ajax(options);
		request
			.then(response => {
				groups = groupBy(response, 'name');
				addresses = response;

				showPlacemarks();
				if(!flag) {
					createNav();
				}
				wrapper.height("")
			})
			.catch(err => console.log("err: ", err));
	}

	function cleanPlacemarks() {
		savedMarkers.forEach(n => n.setMap(null))
		savedMarkers = [];
	}

	function getInfoWindowContent(data) {
		let str = ''

		str += `${data.address}`;

		if(data.tel) {
			str += `<br />Телефон: <b>${data.tel}</b>`
		}

		if(data.email) {
			str += `<br />Эл.почта: <a href="mailto:${data.email}">${data.email}</a>`
		}

		return `
			<div class="wysiwyg">
				<h4>${data.name}</h4>
				<p>${str}</p>
			</div>
		`
	}

	let mapInfoWindow = null;

	function showPlacemarks(name) {
		if(!map || !map.center) return;
		let data = name ? addresses.filter(n => n.name === name) : addresses;
		if (savedMarkers.length) { cleanPlacemarks() }
		const bounds = new google.maps.LatLngBounds();

		data.forEach( n => {
			const myLatLng = new google.maps.LatLng(n.coords[0], n.coords[1]);
			const marker = new google.maps.Marker({
				position: myLatLng,
				map: map
			});

			if(mapInfoWindow) {
				mapInfoWindow = null
			}

			mapInfoWindow = new google.maps.InfoWindow({
				map: map,
		    content: getInfoWindowContent(n)
		  });

			marker.addListener('mouseover', () =>  {
				mapInfoWindow.open(map, marker)
			});
		  bounds.extend(myLatLng);
			savedMarkers.push(marker);
			marker.setMap(map);
		});

		map.fitBounds(bounds);
	}

	function createNav() {
		let btns = [];
		for (let name in groups) {
      const itemWrapper = $('<div />', {
        class: 'sellpoint__item-wrapper',
      });

      const sliderWrapper = $('<div />', {
        class: 'sellpoint__mobile-inner',
      });

			const item = $('<button/>',{
				type: 'button',
				text: name,
				class: 'sellpoint__btn'
			});

			item.on('click', placeNavHandler.bind(item, name))
      btns.push(itemWrapper.append(item, sliderWrapper))
		}
		navContainer.html(btns);
  }
  
  function renderMobileSlider(container, name) {
    const existingSlider = $(container).find('.sellpoint__mobile-slider');
    if (existingSlider.length !== 0) {
      existingSlider.slick("setPosition", 0);
     return
    } else {
      // Слайдера еще нет, создаем его
      const MobileSlider = $('<div />', {
        class: 'sellpoint__mobile-slider'
      });

      groups[name].forEach(n => {
        const slide = createCard(n);
        MobileSlider.append(slide)
      });

      $(container).find('.sellpoint__mobile-inner').append(MobileSlider);
      setTimeout(() => {
        MobileSlider.slick({
          arrows: false,
          dots: true
        });
      }, 100)
      
    }
  }

	function placeNavHandler(name) {
    const itemWrapper = $(this).closest(".sellpoint__item-wrapper");
    $(itemWrapper).addClass('is-active').siblings().removeClass('is-active');
		wrapper.height(wrapper.height())
		showPlacemarks(name);
    createCards(name);
    renderMobileSlider(itemWrapper, name);
	} 

	function createCards(name) {
		pointsSlider.slick('slickRemove', null, null, true);

		setTimeout(() => {
			groups[name].forEach(n => {
				const slide = createCard(n);
				pointsSlider.slick('slickAdd', slide);
				pointsSlider.slick('goTo', 0);
				wrapper.height("")
			});
		}, 0);
	}

	function createCard(card) {
		const tmpl = $('#templateCard').clone();
		tmpl.removeAttr('id');
		tmpl.removeAttr('style');

		const text = tmpl.find('[data-slot="text"]');
		const tel = tmpl.find('[data-slot="tel"]');
		const email = tmpl.find('[data-slot="email"]');
		const address = tmpl.find('[data-slot="address"]');
		const name = tmpl.find('[data-slot="name"]');

		name.html(card.name);
		name.parent().addClass('is-active')

		if(card.text) {
			text.html(card.text);
			text.parent().addClass('is-active')
		}

		if(card.tel) {
			tel.html(card.tel);
			tel.parent().addClass('is-active')
		}

		if(card.email) {
			email.html(`<a href="mailto:${card.email}">${card.email}</email>`);
			email.parent().addClass('is-active')
		}

		if(card.address) {
			address.html(card.address);
			address.parent().addClass('is-active')
		}
		return tmpl
	} 


}(jQuery);